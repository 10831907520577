<script setup lang="ts">
import type { IRate } from "~/interfaces";

import RataClock from "~/components/icons/RataClock.vue";
import BaseForm from "~/components/ui/BaseForm.vue";
import Rata from "~/components/inputs/Rata.vue";

const locale = await getLocale("forms", "rate_power_credit")

defineComponent({
  name: "RatePowerCredit",
});

const timeOut = ref();

onMounted(() => {
  updateStateValue("showFlashMessage", true)
  timeOut.value = setTimeout(() => {
    updateStateValue("flashMessage", 'Affrettati! Un utente ha ricevuto esito positivo alla sua richiesta di prestito proprio ora.')
  }, 5000)
})

onUnmounted(() => {
  clearTimeout(timeOut.value);
  emit("init")
})

const route = useRoute();
const emit = defineEmits(["submit", "upgrade", "init"]);
const credit = computed(() => {
  return route.path !== RATE_UPGRADE_PATH ? getStateValue("selectedCredit") : getStateValue("limitedCredit");
});
const offer = getOffers()
const value = ref<IRate>(offer[0]);
const otherOffers = ref<IRate[]>(powerCreditRates(credit.value));
const limitedOffers = ref<IRate[]>(limitedOffersRates(credit.value));
const selected = ref<IRate>(value.value);

const ourText = computed(() => {
  const taeg = Number(credit.value.value) == POWER_CREDIT_MIN_CREDIT ? POWER_CREDIT_MIN_TAEG : POWER_CREDIT_MAX_TAEG

  return `Rata <strong>${POWER_CREDIT_IMP_RATA}</strong> € TAEG <strong>${taeg}</strong>`
});

const ourTextAlt = computed(() => {
  const taeg = Number(credit.value.value) == POWER_CREDIT_MIN_CREDIT ? POWER_CREDIT_MIN_TAEG : POWER_CREDIT_MAX_TAEG

  return `<span class="md:hidden"><strong>${Number(credit.value)}</strong> €</span> <span class="md:capitalize">rata</span> <strong>${POWER_CREDIT_IMP_RATA}</strong> € TAEG <strong>${taeg}</strong>`
});

const otherText = (model: IRate) => {
  if(model.imp_finanz === POWER_CREDIT_MIN_CREDIT) {
    return `Rata <strong>${POWER_CREDIT_IMP_RATA}</strong> € per <strong>${POWER_CREDIT_RATE}</strong> mesi TAEG <strong>${POWER_CREDIT_MIN_TAEG}</strong>`
  }
  if(model.imp_finanz === POWER_CREDIT_MAX_CREDIT) {
    return `Rata <strong>${POWER_CREDIT_IMP_RATA}</strong> € TAEG <strong>${POWER_CREDIT_MAX_TAEG}</strong>`
  }

  return null;
}

const otherTextAlt = (model: IRate) => {
  if(model.imp_finanz === POWER_CREDIT_MIN_CREDIT) {
    return `<span class="md:hidden"><strong>600</strong> €</span> <span class="md:capitalize">rata</span> <strong>${POWER_CREDIT_IMP_RATA}</strong> € per <strong>${POWER_CREDIT_RATE}</strong> mesi TAEG <strong>${POWER_CREDIT_MIN_TAEG}</strong>`
  }
  if(model.imp_finanz === POWER_CREDIT_MAX_CREDIT) {
    return `<span class="md:hidden"><strong>2.000</strong> €</span> <span class="md:capitalize">rata</span> <strong>${POWER_CREDIT_IMP_RATA}</strong> € TAEG <strong>${POWER_CREDIT_MAX_TAEG}</strong>`
  }

  return null;
}

const isActive = (model:IRate) => {
  return selected.value.imp_credito === model.imp_credito &&
      selected.value.rate === model.rate
};
const toggleSelected = (rate: IRate) => {
  if (window.innerWidth < 1024) selected.value = rate;
};
const submit = (model: IRate, rateType: string | null = null) => {
  updateStateValue("rateType", rateType);
  emit("submit", model);
};

const upgrade = (model: IRate, rateType: string | null = null) => {
  updateStateValue("rateType", rateType);
  emit("upgrade", model);
};
</script>

<template>
  <BaseForm>
    <template #upper>
      <div class="offers my-4 sm:my-8">
        <div>
          <div class="text-center lg:text-left py-1 px-2 sm:px-16">
            <h1 class="text-xl sm:text-3xl font-semibold">
              {{ locale.title }}
            </h1>
            <h2 class="text-base lg:mt-4">
              {{ locale.subtitle }}
            </h2>
          </div>
          <div class="bg-yellow text-center lg:text-left py-1 mt-4">
            <h3 class="text-base mx-auto px-4 py-1 sm:px-16">
              <span>{{ locale.offer_helper }}</span>
            </h3>
          </div>
        </div>
        <div class="px-2 sm:px-16 mx-auto lg:container pt-6">
          <ul class="mb-4 lg:mb-6" data-testid="rataOur">
            <Rata
              :model="value"
              :active="isActive(value)"
              type="our"
              :text="ourText"
              :textAlt="ourTextAlt"
              @selected="toggleSelected"
              @submit="submit"
            />
          </ul>
          <h3
            class="text-base mb-4 lg:mb-6 mt-10 sm:mt-6 text-center lg:text-left"
          >
            <span>{{ locale.other_offers }}</span>
          </h3>
          <div v-if="otherOffers.length" class="lg:mb-6" data-testid="rataOthers">
            <Rata
              v-for="(model, index) in otherOffers"
              :key="index"
              class="mb-2"
              :model="model"
              :active="isActive(model)"
              :text="otherText(model)"
              :text-alt="otherTextAlt(model)"
              type="other"
              :data-testid="`rataOther${model.rate}`"
              @selected="toggleSelected"
              @submit="submit(model, 'proposal_other')"
            />
          </div>
        </div>
      </div>
      <div class="spacer h-6"></div>
    </template>
    <template
      v-if="limitedOffers.length && route.path !== RATE_UPGRADE_PATH"
      #bottom
    >
      <div
        class="h-full w-full shadow-lg pt-2 rounded-3xl bg-[#363636] px-2 sm:px-16"
      >
        <div class="mx-auto lg:container pt-2 sm:pt-5 pb-2">
          <h2
            class="mb-2 sm:mb-4 lg:mb-6 text-white text-sm lg:text-3xl font-semibold justify-center lg:justify-start flex items-center flex-wrap"
          >
            <div class="mr-2 mb-2 sm:mb-0 w-full sm:w-auto text-center">
              <RataClock class="h-7 mx-auto" />
            </div>
            <p>{{ locale.limited_offers }}</p>
          </h2>
          <ul data-testid="rataLimited">
            <Rata
              v-for="(model, index) in limitedOffers"
              :key="index"
              :model="model"
              :active="isActive(model)"
              class="mb-2 lg:mb-3"
              type="limited"
              :data-testid="`rataLimited${model.rate}`"
              @selected="toggleSelected"
              @submit="upgrade(model, 'proposal_limited')"
            />
          </ul>
        </div>
      </div>
    </template>
  </BaseForm>
</template>

<style scoped></style>
